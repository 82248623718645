h4.dividing {
  font-weight: 400;
  text-transform: uppercase;
  margin-top: calc(2rem - .14285714em);
}

h4.dividing::after {
  content: '';
  display: block;
  border-top: 1px solid rgba(34,36,38,.15);
  border-bottom: 1px solid #ffffff11;
  margin: 1rem 0 2rem 0;
}
