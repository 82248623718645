@keyframes hide {
  from {
    margin: 1em 0;
    opacity: 1;
    padding: 1em 1.5em;
  }
  25% {
    opacity: 0;
  }
  75% {
    display: none;
  }
  to {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
  }
}

.ui.message.hide {
  animation: 0.125s ease 0s normal forwards hide;
  display: none;
  opacity: 0;
  overflow: hidden;
}

.ui.message.show {
  opacity: 1;
}
