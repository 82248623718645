#root .account-cards {
    clear: both;
}

.supporting_doc {
    max-width: 95%;
    height: auto;
}

.currenex {
    color: #2476c3;
}

#balances-date-picker {
    margin-top: 8px;
    margin-bottom: 10px;    
    margin-right: 20px;
}
