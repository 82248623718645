body {
  height: 100%;
  margin: 0px;
  /*removes default style*/
  box-sizing: border-box;
  background-color: #fdfdfd !important;
}

#root {
  height: 100%;
  width: 100%;
}

.grid-container {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
}

.content-container {
  height: 100%;
  transition: flex @layout-transition-duration ease;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden !important;
}

.ui.container.tab-container {
  margin: 0 !important;
}

.main-content {
  padding: 5px 10px 10px 10px;
  animation: appear 0.5s ease;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}

#top-nav {
  margin-top: 0 !important;
  grid-column: content;
  grid-row: span header;
  width: 100%;
  height: @profile-icon*1.5;
  display: flex !important;
  z-index: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  grid-area: top-nav;
  transition: @layout-transition-duration cubic-bezier(0.68, -0.55, 0.265, 1.55) (@layout-transition-duration / 2);
}

.logo {
  animation: fade-in @layout-transition-duration ease 0.25s;
  background-image: url("../../../assets/logos/Cboe_Clear_RGB_Dark.svg");
  background-repeat: no-repeat;
  background-size: @logo-width @logo-height;
  width: @logo-width;
  height: @logo-height;
  display: inline-block;
}

.big-logo {
  background-image: url("../../../assets/logos/Cboe_Clear_RGB_Dark.svg");
  background-repeat: no-repeat;
  background-size: @logo-width*1.8 @logo-height*1.8;
  width: @logo-width*1.8;
  height: @logo-height*1.8;
  display: inline-block;
}

.small-logo {
  animation: fade-in @layout-transition-duration ease 0.25s;
  background-image: url("../../../assets/logos/Cboe_Clear_RGB_Dark.svg");
  background-repeat: no-repeat;
  background-size: (@logo-height / 2) (@logo-height / 2);
  width: (@logo-height / 2);
  height: (@logo-height / 2);
  display: inline-block;
}

.smaller-logo {
  animation: fade-in @layout-transition-duration ease 0.25s;
  background-image: url("../../../assets/logos/Cboe_Clear_RGB_Dark.svg");
  background-repeat: no-repeat;
  background-size: (@logo-height / 2.5) (@logo-height / 2.5);
  width: (@logo-height / 2.5);
  height: (@logo-height / 2.5);
  display: inline-block;
}

#root .header {
  font-weight: 400;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

#root .text-right {
  text-align: right;
}

#root .text-left {
  text-align: left;
}

.text-center {
  text-align: center !important;
}

.vert-flex {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.back-link,
.back-link * {
  cursor: pointer;
  transition: all 0.25s ease;
}

.back-link:hover {
  color: @color-primary-0 !important;
  * {
    color: @color-primary-0;
  }
}

.back-link:active {
  color: #00b891 !important;
  * {
    color: #00b891;
  }
}

.show-expired {
  display: flex !important;
  justify-content: flex-end;
  padding-bottom: 5px;
}

.show-delisted {
  display: flex !important;
  justify-content: flex-end;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.blue-circle {
  color: #1282b8;
}

.full-width {
  width: 100% !important;
}

.ddlOnboardingEmployees-width {
  width: 265px;
}

.ddlOnboardingUsers-width div {
  min-width: 450px;
}

.center-flex {
  width: 100%;
  margin: auto;
  display: flex;
}

.center {
  width: 100%;
  margin: auto;
  text-align: center;
}

.tradesModalHeader {
  color: #828282;
  font-size: 21px;
}

.margin-bottom-21 {
  margin-bottom: 21px !important;
}

.margin-bottom-140 {
  margin-bottom: 140px !important;
}

.onboarding-note {
  width: 90%;
  margin-left: 10px;
}

.member-user-note-comments {
  display: block !important;
  margin-top: 10px;
  margin-left: 0px !important;
}

.float-right-with-margin {
  float: right !important;
  margin-right: 5% !important;
  font-size: 18px !important;
}

.member-user-note-separator {
  border-top: none;
  border-bottom: 1px solid lightgray;
}
.blue-action-color {
  color: #1282b8;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-right-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-right-10 {
  margin-right: 10px;
}

.no-margin {
  margin: 0px !important;
}

.max-width-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.min-height-25 {
  min-height: 25px;
}

.button-large-width {
  width: 207px;
  height: 60px;
}

.button-big-width {
  width: 180px;
  height: 50px;
}

.grey-bold {
  color: #828282;
  font-weight: 500;
}

.clickable-table-row {
  &:hover {
    background-color: #00ebb9 !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.settlement-service-pending {
  color: grey !important;
}
.settlement-service-alleged {
  color: rgb(0, 0, 255) !important;
}
.settlement-service-cleared {
  color: green !important;
}
.settlement-service-declined {
  color: rgb(206, 19, 19) !important;
}
.settlement-service-cancelled {
  color: rgb(203, 142, 29) !important;
}
.settlement-service-admin-cxl {
  color: rgb(203, 142, 29) !important;
}

.hidden-column {
  overflow: hidden !important;
}

.w95 {
  width: 95px;
}

.w200 {
  width: 200px;
}
