.ReactTable .rt-thead.-header {
    box-shadow: none;
    background: white;
}

.ReactTable .rt-thead.-headerGroups {
    background: #F1F1F1;
}

.ReactTable.-striped:not(.no-stripe) .rt-tr.-odd {
    background: #F1FAFD;
}

.ReactTable.-striped:not(.no-stripe) .rt-tr.-even {
    background: #FFFFFF;
}

.ReactTable .rt-th {
    overflow: visible !important;
  }

.ReactTable.-highlight .rt-tbody .rt-tr:not(.rt-th):hover {
  background-color: #00ebb9 !important;
}

.ReactTable.with-subtable > .rt-table > .rt-tbody > .rt-tr-group:nth-child(odd) {
  background-color: #f1fafd;
}

.ReactTable.compact * {
  line-height: 1em;

  .rt-td {
    padding: 4px 5px;
  }
}

.rt-tr-group .ReactTable.subtable {
  box-shadow: 0 2px 3px 0 #d0d0d0;
  margin: 2em;
}

.td-with-dropdown {
    overflow: visible !important;
}

.rt-expander.vertical-align-super {
  vertical-align: super
}

.rt-td .table-filter-text {
    padding-right: .5em;
}

.rt-td .table-filter-cell:hover .table-filter-icon {
    display: table-cell;
}

.rt-td .table-filter-icon {
    margin-left: .5em;
    display: none
}

.rt-cell-1 {
  padding-left: 15px;
}

.rt-cell-2 {
  padding-left: 40px;
}
