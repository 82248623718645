.referral-wrapper {
  padding: 15px 10px;
  > :last-child {
    margin-top: 20px;
  }
}

.referral-wrapper:last-child {
  margin-top: 20px;
}

.toggle-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle-input > p {
  color: #1282b8;
  margin-left: 15px;
  font-size: 15px;
  cursor: pointer;
}

.user-referral-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.copy-referral {
  margin-left: 15px !important;
  color: #bbb9b9;
  cursor: pointer;
}

#user-referral-code-input {
  color: #828282 !important;
  font-weight: bold;
}

.referral-table-header {
  font-size: 15px;
}

.referral-table-status {
  text-transform: capitalize;
}

.referral-table-earned {
  text-align: right;
}

.referral-bonus-balance {
  font-size: 17px;
  font-weight: bold;
  color: #6f6e6e;
}

.promotion-table-cell {
  height: 27px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.promotion-table-cell.status {
  justify-content: center;
}
.promotion-buttons-container {
  display: flex;
  justify-content: space-around;
  padding: 0px 5px;
}

.promotion-buttons-container :nth-child(1) {
  margin-right: 10px;
}
.promotion-button {
  border-radius: 2px;
  font-size: 12px;
  padding: 6px 10px 6px 10px;
  flex: 1;
  color: #5a5a5a;
  font-weight: 600;
  cursor: pointer;
 
}
.promotion-button:focus {
  outline: none;
  background-color: red;
}

.promotion-button.approve {
  background-color: #03ebb9;
  border: 1px solid #03ebb9;
}

.promotion-button.reject {
  background-color: #eceaea;
  border: 1px solid #eceaea;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table-test {
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    td {
      background-color: red;
    }
  }
}