#root .add-button {
  margin-right: 10px;
  margin-bottom: 10px;
  background: @color-primary-0
}

.ui.button.add-new {
  margin: 0.75rem 0;
}

.ui.button.link {
  background: none!important;
  border: none;
  padding: 0!important;
  /*input has OS specific font-family*/
  color: #069 !important;
  cursor: pointer;
  font-weight: unset;

  &.edit {
    text-decoration: underline;
    margin-left: 15px;
    margin-bottom: 15px;
    font-size: larger;
  }
  &.edit:hover {
    cursor: pointer;
  }

  &.negative {
    color: red;
  }
}
