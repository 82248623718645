#root .ui.statistics {
    clear: both;
    padding-left: 10px;
    
     > .statistic {

        .unmatched {
            color: red;
        }

        .matched {
            color: green !important;
        }
    }
}

#root .statistics-header {
    margin-bottom: 0px;
}

.rt-tr.positive {
    background-color: @positive
}

.rt-tr.negative {
    background-color: @negative
}

.rt-tr.negative.-odd {
    background-color: @negative !important
}

.rt-tr.negative.-even {
    background-color: @negative !important
}

.pointer {
    cursor: pointer;
}

#root .reconciliation-comments {
    justify-content: space-between;
    padding: 20px
}

#root .ui.comments.reconciliation {
    flex: 1;
    margin: inherit !important;
}

.ReactTable.reconciliation .rt-thead.-headerGroups {
    font-size: x-large;
    padding: none;
}
.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}
