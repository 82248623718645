.env-banner {
  grid-area: env-banner;
  width: 100%;
  display: block;
  background-color: #F2711C;
  color: #ffffff;
  text-align: center;
  font-size: 1.5em;
  height: 2.5rem;
  line-height: 2.2rem;
}
 
.notif-banner {
  grid-area: notif-banner;
  width: 100%;
  display: block;
  background-color: #F2711C;
  color: #ffffff;
  text-align: center;
  font-size: 1.1em;
  line-height: 2.2rem;
}