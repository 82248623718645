.user-settings-segment {
    max-width: 50%;
}

.dropdown-header {
    font-weight: 600 !important;
    color: black;
    opacity: 100% !important;
    text-transform: uppercase;
    font-size: 1.1rem !important;
} 
