.details-header {
  display: flex;
  justify-content: space-between;
}
.detail-segment {
  display: flex;
  padding: 10px !important;
  margin-right: 2px;
  align-items: flex-start;
  flex-wrap: wrap;
  border-radius: 0px !important;
}

.detail-segment:not(:last-child) {
  border-bottom: 1px @lightGrey dashed;
  margin-bottom: 10px;
}

.detail-item.header {
  font-size: 29px;
}

.detail-item {
  min-height: 93px;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  .detail-title {
    font-size: 11px;
    margin-bottom : 2px;
    color: @darkGrey;
    text-transform: uppercase;

    &.profile {
      color: @green !important;
    }
  }

  .detail-text {
    font-size: 16px;
  }
}

.label-segment {
  display: flex;
  padding: 10px !important;
  margin-right: 2px;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-radius: 0px !important;
}

// edit weird height on adjacent edit button
.label-segment + .ui.button {
  margin: 5px;
}

.label-item {
  display: flex;
  padding-bottom: 5px;
  padding-right: 20px;
  .label-title {
    font-size: 11px;
    text-transform: uppercase;
  }
}

.detail-column {
  display: flex;
  flex: 0 0 calc(100%/6);
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;

  :last-child {
    padding-bottom: 0px;
    min-height: 35px;
  }
}

.tcr-details {
  display: flex;
  justify-content:space-around;
  align-items: center;

  .ui.table {
    margin: 0;
  }
}
.contract-details {
  white-space: nowrap;
  padding: 0 1em;

  .detail-column {
    padding: 0em 1.25em;
  }
}
