.topHeader {
  display: flex !important;
  border-bottom: solid 1px lightgrey;
  p {
    top: 56px;
    font-family: "Apercu";
    font-size: 15px;
    line-height: 22px;
  }
  Button {
    width: 270px;
    height: 60px;
    font-size: 18px !important;
  }

  .resetBtn {
    background: white !important;
    border: solid 1px black !important;
  }

  .rejectBtn {
    background: #dd3838 !important;
    color: white !important;
  }
}

.lastUpdated {
  top: 56px;
  font-family: "Apercu" !important;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px !important;
}

.modalHeaderSizes {
  font-size: 18px;
  margin-bottom: 14px;
}

.modalStyles {
  width: 43em;
  margin: auto;
  border: 1px solid #d1e5ec;
  background: #f3f9fb;
  padding: 2%;

  div {
    display: flex;
    font-family: "Apercu";
    font-style: normal;
  }

  .title {
    font-size: 17px;
  }

  .row {
    font-size: 16px;
    color: #828282;
    font-weight: normal;
  }

  p {
    width: 40%;
    text-align: left;
  }
}

.modalEditMain {
  width: 43em;
  margin: auto;
  margin: auto;
  border: 1px solid #d1e5ec;
  background: #f3f9fb;
  padding: 1%;

  div {
    display: flex;
    font-family: "Apercu";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #828282;
  }

  p {
    width: 40%;
    text-align: left;
  }
}
