.ui.modal.visible:not(.show-overflow) {
  overflow: auto;
}

.modal .modal-form-actions {
  text-align: right;

  .field {
    display: inline-block;
  }
}

.modal .space-between {
  justify-content: space-between;
  display: flex;
}

.memberUserOnboardingMain {
  margin-top: 42px !important;
  margin-bottom: 29px !important;
  text-align: center;
  font-size: 18px;
  color: #4f4f4f;
}

.center-authorization-ip {
  gap: 2%;
}

.center-authorization-ip div {
  width: 100%;
}

.modal.revert-overflow {
 overflow: revert !important;
}
