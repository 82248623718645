.segment.extra-compact {
  padding: 0!important;
}

.segment.compact-filters {
  padding: 0!important;
  margin: 0 0 0 6px !important;

  .fields {
    flex-wrap: wrap;
  }
}

.segment.center-vertical {
  height: 75vh;
}
