.log-severity-0 {
    color: #666;
}

.log-severity-1 {
    color: steelblue;
}

.log-severity-2 {
    color: darkgoldenrod;
}

.log-severity-3 {
    color: red;
}

.log-severity-4 {
    color: darkred;
    font-weight: bold;
    }

.task-status-success {
    color: green;
}

.task-status-fail {
    color: red;
}
