.fcm-reports-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > :last-child {
    margin-left: 20px !important;
  }
}

.fcm-parsed-report-desc {
  padding: 1.5rem 1.5rem 0px 1.5rem;

  .fcm-report-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px
  }
}

.fcm-report-details > h3 {
  margin-bottom: 0px;
}

.info-item {
  display: flex;
  flex-direction: column;

  .info-item-title {
    font-size: 11px;
    margin-bottom : 2px;
    color: @darkGrey;
    text-transform: uppercase;
  }

  .info-item-text {
    font-size: 16px;
  }
}

.capitalised {
  text-transform: capitalize !important;
}