.ui.table tr td.border-right:not(:last-child) {
  border-right: 1px solid @lightGrey!important;
}

.ui.table.extra-compact tr td {
  padding: 1px 5px;
}

.ui.table.extra-compact thead tr th {
  padding: 4px 5px;
  white-space: nowrap; // don't wrap header rows
}

.postings-table {
  margin: 1em 1em !important;
}
