#root .nav-container {
  grid-area: sidebar;
  justify-self: left;
  z-index: 4;
  background-color: @nav-background-color;
  transition: transform @layout-transition-duration cubic-bezier(0.19, 1, 0.22, 1);

  // remove circular box shadow so padding stays constant when it rotates,
  //  but padding is visually identical
  .icon:first-child {
    box-shadow: none;
    -webkit-box-shadow: none;
    margin-right: 0;
  }

  .nav {
    overflow-y: scroll;
    background-color: inherit;
    transition: inherit;
    min-height: 100%;
    color: #dcdce1;
    height: 100%;
    align-self: flex-start;
    border-radius: 0;
  }
}

.nav-container.closed {
  margin-right: @nav-collapsed-width;
  transform: translateX((-1 * @nav-width));
  .nav {
    position: absolute;
    transform: translateX(@nav-collapsed-width);

    // hide icon when not hovered so it doesn't clash with floating button
    .icon:first-child {
      opacity: 0;
    }
  }
}

.smaller-logo.closed-nav {
  position: absolute;
  top: 0;
  left: calc(@nav-width + 0.333em);
  cursor: pointer;
  border-radius: 0 25px 25px 0;
  transition: transform @layout-transition-duration cubic-bezier(0.19, 1, 0.22, 1),
              opacity @layout-transition-duration cubic-bezier(0.19, 1, 0.22, 1);
  background-color: @color-primary-cboe-clear;
  box-shadow: 0 1px 2px 0 @nav-background-color;
  border-radius: 25px;
  background-size: (@logo-height / 3.25);
  background-position: center;
  height: (@logo-height / 4);
  width: (@logo-height / 4);
  padding: 1.5em!important;
  margin-top: 0.333em;
}

.smaller-logo.closed-nav:hover {
  background-color: @color-primary-cboe-clear;
}

// show right icon when nav has :hover
.nav-container.closed .nav:hover > .icon:first-child {
  opacity: 1;
}

// move nav and its sibling expander button.
// it moves separately so we don't expand the navbar when hovering on the expand button.
.nav-container.closed .nav:hover,
.nav-container.closed .nav:hover + .smaller-logo.closed-nav {
  transform: translateX(@nav-width);
}

.nav-container.closed .nav:hover + .smaller-logo.closed-nav {
  opacity: 0;
}

.nav {
  // show scrollbar for overflowed items
  width: @nav-width !important;

  /* TODO: this should not be so hard coded -- make the layout more fluid */
  .nav-version {
    position: relative;
    top: -14px;
    font-size: 80%;
    margin-left: 22%;
    color: #787878;
  }
}

.nav.menu {
  margin: 0 !important;
}

.nav .nav-items {
  .item {
    line-height: 0 !important;
  }

  .header.item {
    color:#879cb1;
    font-size: .8rem;
    padding-top: 2em !important;
  }

  .item:not(.header) {
    padding-left: 1.5em;
  }

  .nav-item.active {
    color:@nav-item-color-active;
  }

  .nav-item:not(.active):not(.header) {
    color:@nav-item-color;
  }

  .item:not(.active):not(.header):hover {
    background-color:@nav-item-color-active;

    .nav-item {
      color: @nav-background-color;
    }
  }
}
