.ui.progress .bar {
  min-width: 0!important; // show precise percentages instead of min-width: 2em;
}

.ui.progress.compact:last-child {
  margin: 0;
}

.ui.progress.compact > .label {
  top: -0.25em;
  bottom: 0;
  margin-top: 0;
  text-shadow: 0px 1px 1px #fff;
}

.ui.progress.compact.error > .label {
  color: #fff;
  text-shadow: 0px 1px 2px #912d2b;
}
