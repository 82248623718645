.riskTable {
  display: block;
  max-width: 100%;
  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    height: 75vh;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    tr:nth-child(even) {
      background-color: @lightGrey;
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
}

.risk-alert-detail-segment {
  max-width: 78%;
}

.riskTableX {
  display: block;
  max-width: 100%;
  overflow-x: auto;
  table {
    td {
      overflow: auto;
    }
  }
}

.fixed {
  position: fixed;
}
