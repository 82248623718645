.member-settings {
    .fields .field{
        margin: 10px 0!important;
    }
    .ui.checkbox {
        position: absolute;
        bottom: -20px;
        font-style: italic;
        font-size: 11px;
        white-space: nowrap;
    }
}

.instant-ach-enabled {
    align-self: center;
    .ui.checkbox {
        position: relative !important;
        font-style: italic;
        font-size: 11px;
        white-space: nowrap;
        padding-bottom: 15px;
        margin-left: 10px;
    }
}