.xwizard-main {
  padding: 0px;
  border: 1px solid rgba(34,36,38,.15);
  min-height: 50%;
  .title {
    font-weight: 500 !important;
  }
}

.xwizard-nav {
  margin: 0;
  border-left: none!important;
  border-top: none!important;
  border-bottom: none!important;
  .step {
    max-height: 100px;
  }
}

.xwizard-main.horizontal {
  flex-direction: column;

  .xwizard-nav {
    border-bottom: 1px solid rgba(34,36,38,.15)!important;
  }
}

.xwizard-footer {
  display: flex;
  justify-content: space-between;
}

.xwizard-content {
  display: flex;
  flex: 1;
  padding: 15px 10px 10px 25px;
  flex-direction: column;
  justify-content: space-between;
  label {
    font-weight: 500 !important;
  }
}

.xwizard-form-group {
  margin-bottom: 40px;

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child
  {
    font-weight: 400;
    text-transform: uppercase;
  }

  .flex-row.space-between:first-child {
    align-items: flex-end;
  }
}
