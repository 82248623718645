.sidebar-content {
    padding: 0px 5px 5px 5px;
    margin-top: 25px;
}

.flex-column {
    display: flex;
    flex-direction: column;

    &.flex-column-logo {
      align-items: flex-end; 
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.space-between {
    justify-content: space-between;
}

.reconciliation-header {
    justify-content: space-between;
}

.centered {
    justify-content: center;
}