.command-line .input input {
  background-color: #252525 !important;
  color: @lightGrey!important;
  font-family: 'Apercu Mono' !important;
}

.ui.input.copy input {
  border: none;
}

// color adjacent copy icon as well
.ui.input.copy + .icon.copy {
}

// only show padding between input and icon, adjust min-height to text size
.ui.input.copy.compact input {
  padding: 0 0 0 0;
  min-height: 1em;
}
