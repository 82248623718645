.ui.input.datetime {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
}

.ui.input {
  &.inherit {
    position:inherit;
    display: inherit;
  }
}

.dropdown.autolistbox {
  .menu[role="listbox"] {
    width: auto !important;
  }
}

.highZIndex{
  z-index: 9999;
}