.ui.form {
  .field.warning,
  .field.warning:active,
  .field.warning:focus {
    ::placeholder {
      color: @warning-message-bg;
    }
    label {
      color: @warning-label;
    }
    .input {
    }
    input {
      background-color: @warning-message-bg;
      border-color: @warning-border;
    }
  }

  label.field.nowrap {
    white-space: nowrap;
  }
}
